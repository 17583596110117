import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LoginSessionService} from './login-session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginSessionService: LoginSessionService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      headers: this.loginSessionService.loginSession ? req.headers.set('PhpBackupLoginSession', this.loginSessionService.loginSession) : req.headers.delete('PhpBackupLoginSession')
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
