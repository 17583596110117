import {Component} from '@angular/core';
import {NotificationService} from '../notification.service';
import {LoginService} from 'api-client';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-password-lost',
    templateUrl: './password-lost.component.html',
    styleUrls: ['./password-lost.component.scss'],
    standalone: true,
    imports: [FormsModule, NgClass, NgIf]
})
export class PasswordLostComponent {

    loading = false;
    email: string;

    constructor(private loginService: LoginService,
                private notify: NotificationService) {
    }

    submit() {
        this.loading = true;
        this.loginService.triggerPasswordReset(this.email)
            .subscribe(() => {
                this.loading = false;
                const title = 'Password reset link sent successful';
                const msg = 'Your password reset link was sent to your login email! ' +
                    'Check your spam folder if it doesn\'t show up within the next minutes.';
                this.notify.success(msg, title);
            }, error => {
                this.loading = false;
                this.notify.error('Could not send password reset!', error.message);
            });
    }
}
