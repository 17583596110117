import {ApplicationConfig, ErrorHandler, Inject, InjectionToken, PLATFORM_ID} from '@angular/core';
import {appRoutes} from './app-routing/app-routing.module';
import {Configuration} from 'api-client';
import {environment} from '../environments/environment';
import {provideClientHydration} from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  provideRouter,
  ROUTER_CONFIGURATION,
  RouteReuseStrategy,
  withRouterConfig
} from '@angular/router';
import {CbfpRoutingExtraOptions} from './cbfp-routing-extra-options.service';
import {CbfpErrorHandler} from './cbfp-error-handler';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {AuthInterceptor} from './auth.interceptor';
import {AffiliateService} from './affiliate.service';
import {AuthService} from './auth.service';
import {LocalStorageService} from './local-storage.service';
import {ConstantsService} from './constants.service';
import {LoginGuardService} from './login-guard.service';
import {UrlService} from './url.service';
import {NotificationService} from './notification.service';
import {CopyAppService} from './copy/copy-app/copy-app.service';
import {PodioOrgsService} from './backup/podio-orgs.service';
import {CopyItemService} from './copy/copy-item/copy-item.service';
import {provideAnimations} from '@angular/platform-browser/animations';
import {isPlatformBrowser} from "@angular/common";

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: Configuration,
      useFactory: (platformId: Object) => new Configuration({basePath: isPlatformBrowser(platformId) ? environment.baseUrl : 'https://www.cloud-backup-for-podio.com'}),
      deps: [PLATFORM_ID]
    },
    {
      provide: RouteReuseStrategy,
      useValue: new class extends BaseRouteReuseStrategy {
        shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
          return future.toString() === curr.toString(); // reuse only identical routes
        }
      }
    },
    provideRouter(appRoutes, withRouterConfig({onSameUrlNavigation: 'reload'})),
    provideClientHydration(),
    {provide: ROUTER_CONFIGURATION, useClass: CbfpRoutingExtraOptions},
    {provide: ErrorHandler, useClass: CbfpErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    AffiliateService,
    AuthService,
    LocalStorageService,
    ConstantsService,
    LoginGuardService,
    UrlService,
    NotificationService,
    CopyAppService,
    PodioOrgsService,
    CopyItemService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
};
