<div class="container mb-4">
  <h1 class="py-4">Register</h1>

  <div class="row row-cols-1 row-cols-sm-2 g-4">
    <div class="col">
      <div class="row row-cols-1 gy-4">
        <div class="col">
          <div class="card h-100">
            <div class="card-body">
              <h4 class="card-title">Register for free!</h4>
              <div class="card-text">
                It's free (100 MB) - give it a try!
                <ul>
                  <li> We won't give any of your data to third-parties.</li>
                  <li> We won't email you (except for really important notifications).</li>
                  <li> We won't transfer any of your data or credentials over insecure channels.</li>
                </ul>
              </div>
              <app-promotion-notification></app-promotion-notification>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <div class="card-body">
              <h4 class="card-title">Register via Podio</h4>
              <p class="card-text">Cloud Backup for Podio supports two authentication flows. Either you
                register via Podio (OAuth), or you provide your Podio credentials directly.
              </p>
              <p class="card-text">
                <app-oauth-link [block]="true" [redirect]="'/view/backupcollection'">
                  Register via Podio
                </app-oauth-link>
              </p>
              <p class="card-text text-muted">You can always enable
                further/other authentication on the account page, once you are logged in. </p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col">
      <div class="card h-100">
        <div class="card-body">
          <h4 class="card-title">Register with Podio credentials</h4>
          <form (ngSubmit)="register()" class="card-text" #registerForm="ngForm">
            <div class="mb-3 required"
                 [ngClass]="{'has-danger': cbfp_login.errors && (cbfp_login.dirty || cbfp_login.touched)}">
              <label for="exampleInputEmail1">Email</label>
              <input type="email" class="form-control" id="exampleInputEmail1"
                     aria-describedby="emailHelp"
                     pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                     placeholder="Enter email" required [(ngModel)]="login" name="login"
                     #cbfp_login="ngModel">
              <div *ngIf="cbfp_login.errors && (cbfp_login.dirty || cbfp_login.touched)">
                <div class="form-control-feedback">Enter valid email!</div>
              </div>
              <small id="emailHelp" class="form-text text-muted">Your email will be your login.</small>
            </div>
            <div class="mb-3 required">
              <label for="cbfp_password">Password</label>
              <input type="password" class="form-control" placeholder="Password" [(ngModel)]="password"
                     maxlength="255"
                     name="cbfp_password" id="cbfp_password" required #cbfp_password="ngModel">
            </div>
            <div class="mb-3 required"
                 [ngClass]="{'has-danger': cbfp_password_confirm.errors && (cbfp_password_confirm.dirty || cbfp_password_confirm.touched)}">
              <label for="cbfp_password_confirm">Confirm password</label>
              <input type="password" class="form-control" id="cbfp_password_confirm"
                     placeholder="Confirm password" name="cbfp_password_confirm"
                     [(ngModel)]="password_confirm" maxlength="255"
                     required validateEqual="cbfp_password" #cbfp_password_confirm="ngModel">
              <div
                *ngIf="cbfp_password_confirm.errors && (cbfp_password_confirm.dirty || cbfp_password_confirm.touched)">
                <div class="form-control-feedback">Passwords don't match!</div>
              </div>
            </div>
            <div class="mb-3 required">
              <label for="podioLogin">Podio login</label>
              <input type="email" class="form-control" id="podioLogin" [(ngModel)]="podio_login"
                     placeholder="Enter your Podio login" required name="podio_login"
                     (ngModelChange)="showWrongPodioCredentialsInfo = false">
            </div>
            <div class="mb-3 required">
              <label for="podioPassword">Podio password</label>
              <input type="password" class="form-control" id="podioPassword"
                     aria-describedby="podioLoginHelp"
                     placeholder="Podio password" required [(ngModel)]="podio_password"
                     name="podio_password" (ngModelChange)="showWrongPodioCredentialsInfo = false">
              <small id="podioLoginHelp" class="form-text text-muted">
                You can
                <app-oauth-link [logo]="false" [button]="false" [redirect]="'/view/backupcollection'">
                  register via Podio
                </app-oauth-link>
                , if you don't like to share your Podio credentials.
              </small>
              <small class="form-text text-danger" *ngIf="showWrongPodioCredentialsInfo">
                Please enter correct Podio login/password to create a backup account!
              </small>
            </div>
            <span ngbPopover="Please fill all fields!" triggers="mouseenter:mouseleave"
                  [disablePopover]="registerForm.form.valid">
                            <button type="submit" [disabled]="!registerForm.form.valid"
                                    class="btn btn-primary btn-block" #registerButton>
                                <i *ngIf="loading_register" class="fa fa-spinner fa-pulse fa-fw"></i>
                                Register
                            </button>
                        </span>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
