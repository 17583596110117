import {map, share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {Location} from '@angular/common';
import {PromotionApiModel, PromotionService} from 'api-client';

const PROMO_URL_PART = 'promo/';
const PROMO_COOKIE_NAME = 'cbfp_promo';

@Injectable()
export class AffiliateService {

    promotion: Observable<PromotionApiModel> = null;

    constructor(private promotionService: PromotionService,
                private localStorage: LocalStorageService,
                private location: Location) {
        const promoName = this.findPromo();
        if (promoName) {
          this.promotion = this.promotionService.getPromotion(promoName).pipe(
                map(result => {
                    this.localStorage.set(PROMO_COOKIE_NAME, promoName, 7);
                    return result;
                }),
                share(), );
        } else {
            this.promotion = EMPTY;
        }
    }

    private findPromo() {
        const path = this.location.path(false);
        const promoUrlIndex = path.indexOf(PROMO_URL_PART);
        let promoName = null;
        if (promoUrlIndex > -1) {
            promoName = path.substring(promoUrlIndex + PROMO_URL_PART.length);
        } else { // check promo cookie:
            const promoCookie = this.localStorage.get(PROMO_COOKIE_NAME);
            console.log('promo cookie: ', promoCookie);
            if (promoCookie) {
                promoName = promoCookie;
            }
        }
        return promoName;
    }

}
