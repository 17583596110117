import {Component, OnInit} from '@angular/core';
import {AffiliateService} from '../affiliate.service';
import {ConstantsService} from '../constants.service';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-promotion-notification',
    templateUrl: './promotion-notification.component.html',
    styleUrls: ['./promotion-notification.component.scss'],
    standalone: true,
    imports: [NgIf, NgbAlert, AsyncPipe, DecimalPipe]
})
export class PromotionNotificationComponent implements OnInit {

    hostname: string;
    closed = false;

    constructor(public affiliate: AffiliateService,
                private constants: ConstantsService) {
    }

    ngOnInit() {
        this.hostname = this.constants.host
    }
}
