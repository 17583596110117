import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import {ConstantsService} from '../constants.service';
import {Observable, of} from 'rxjs';
import {AffiliateService} from '../affiliate.service';
import {AuthService} from '../auth.service';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-oauth-link',
    template: `<a [href]="(registerUri | async) + getState(redirectUrl | async, promotion | async)"
                  [ngClass]="{'btn-block': block, 'btn': button, 'btn-outline-primary': button}">
        <img width='15' height='15' src='/assets/podio_logo_only.png' alt='Podio logo' *ngIf="logo">
        <ng-content></ng-content>
    </a>`,
    styleUrls: ['./oauth-link.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, AsyncPipe]
})
export class OauthLinkComponent implements OnInit {

    @Input()
    block = false;
    /**
     * Overwrites default redirect (see auth.service)
     */
    @Input()
    redirect: string = null;
    @Input()
    logo = true;
    @Input()
    button = true;

    registerUri: Observable<string> = null;
    redirectUrl: Observable<string> = null;
    promotion: Observable<string> = null;

    constructor(public constants: ConstantsService,
                private affiliate: AffiliateService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.registerUri = this.constants.getAsObservable('registerUri').pipe(startWith('/'));

        if (this.redirect) {
            this.redirectUrl = of(this.redirect);
        } else {
            this.redirectUrl = this.authService.redirectUrl;
        }

        this.promotion = this.affiliate.promotion.pipe(
            map(promo => promo.name ? promo.name : ''),
            startWith(''),
            distinctUntilChanged(), );
    }

    public getState(path: string, promo: string) {
        const state = '&state=' + encodeURIComponent(JSON.stringify({
                'promo': promo,
                'redirect': path
            }));
        return state;
    }
}
