<div class="container">
    <h1 class="py-4">Password Lost?</h1>

    <div class="card-deck">
        <div class="card">
            <div class="card-body">
                <p class="card-text">
                    If you lost your password, you can request a reset link to be send to your login email address. This
                    reset link is valid 24 hours and with it you can set a new password for your login.
                </p>
                <p class="card-text">
                    <em>Note: Your login is case insensitive (but your password is case sensitive)!</em>
                </p>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Request password reset</h4>
                <div class="card-text">
                    <form #passwordLostForm="ngForm" (ngSubmit)="submit()">
                        <div class="mb-3 required"
                             [ngClass]="{'has-danger': email_field.errors && (email_field.dirty || email_field.touched)}">
                            <label for="password_lost_mail">Login</label>

                            <input type="email" required [(ngModel)]="email" id="password_lost_mail" name="email"
                                   #email_field="ngModel" class="form-control"
                                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">

                            <div *ngIf="email_field.errors && (email_field.dirty || email_field.touched)">
                                <div class="form-control-feedback">Must enter valid email address!</div>
                            </div>
                        </div>

                        <button type="submit" [disabled]="!passwordLostForm.form.valid || loading"
                                class="btn btn-primary btn-block">
                            <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw"></i>
                            Send reset link
                        </button>
                    </form>
                </div>
            </div>
        </div>

    </div>

</div>
