import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from './auth.service';


@Injectable()
export class LoginGuardService  {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.debug('can activate..');
    return this.authService.isLoggedInOrLoading().pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          console.log('redirecting to login page..');
          this.authService.fixedLoginRedirect(state.url);
          this.router.navigateByUrl('/view/login');
        }
      })
    );
  }
}
