import {Component, OnInit} from '@angular/core';

import {NotificationService} from '../notification.service';
import {AffiliateService} from '../affiliate.service';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {LoginService} from 'api-client';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { EqualValidator } from '../cbfp-common/directives/equal-validator.directive';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OauthLinkComponent } from '../oauth-link/oauth-link.component';
import { PromotionNotificationComponent } from '../promotion-notification/promotion-notification.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    standalone: true,
    imports: [PromotionNotificationComponent, OauthLinkComponent, FormsModule, NgClass, NgIf, EqualValidator, NgbPopover]
})
export class RegisterComponent implements OnInit {

    login: string;
    password: string;
    password_confirm: string;
    podio_login: string;
    podio_password: string;

    loading_register = false;

    promoName: string = '';
    showWrongPodioCredentialsInfo = false;

    constructor(private loginService: LoginService,
                private affiliate: AffiliateService,
                private authService: AuthService,
                private router: Router,
                private notifyService: NotificationService) {
    }

    ngOnInit() {
        this.affiliate.promotion.pipe(take(1)).subscribe(promo => this.promoName = promo.name ? promo.name : '');
    }

    register() {
        this.loading_register = true;
        this.showWrongPodioCredentialsInfo = false;
        this.loginService.register({
            user: this.login,
            password: this.password,
            podioUser: this.podio_login,
            podioPassword: this.podio_password,
            promo: this.promoName ? this.promoName : null
        }).subscribe(response => {
            this.loading_register = false;
            if (response.validation_podio_sucess) {
              this.notifyService.success(`Registration of user <strong>${this.login}</strong> successful!`);
              this.authService.login(this.login, this.password).then(() => {
                return this.router.navigateByUrl('/view/backupcollection');
              });
            } else {
              this.notifyService.notify(`Podio login/password not correct!`, 'Registration failed', 'warning');
              this.showWrongPodioCredentialsInfo = true;
            }

        }, error => {
            this.loading_register = false;
            this.notifyService.error('Registration failed!', error.message);
        });
    }

}
