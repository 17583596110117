<div class="container">

    <h1 class="py-4">Pricing
        <a routerLink="/view/register" class="btn btn-primary float-end">Register for free now!</a>
    </h1>

    <table class="table table-striped">
        <thead>
        <tr>
            <th></th>
            <th>Free</th>
            <th>Silver</th>
            <th>Gold</th>
            <th>Enterprise*</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <th scope="row">Storage quota</th>
            <td> 100 MB</td>
            <td>2 GB</td>
            <td> 20 GB</td>
            <td> Custom</td>
        </tr>
        <tr>
            <th scope="row">Automatic / scheduled backups</th>
            <td> no</td>
            <td> Monthly,
                weekly
            </td>
            <td> Monthly,
                weekly,
                daily
            </td>
            <td>Monthly,
                weekly,
                daily
            </td>
        </tr>
        <tr>
            <th scope="row">Restore</th>
            <td>Items,
                tasks,
                apps
            </td>
            <td>Items,
                tasks,
                apps
            </td>
            <td>Items,
                tasks,
                apps
            </td>
            <td>Items,
                tasks,
                apps
            </td>
        </tr>
        <tr>
            <th scope="row">Trial period*</th>
            <td>n/a</td>
            <td> 14 days</td>
            <td> 14 days</td>
            <td> Custom</td>
        </tr>
        <tr>
            <th scope="row">Cancellation period</th>
            <td> n/a</td>
            <td> Monthly</td>
            <td> Monthly</td>
            <td> Monthly</td>
        </tr>
        <tr class="table-dark">
            <th scope="row">Price</th>
            <td><strong>Free</strong></td>
            <td> 10 €/month</td>
            <td> 50 €/month</td>
            <td><a class="btn btn-primary" href="mailto:info@cloud-backup-for-podio.com">Contact support</a></td>
        </tr>
        </tbody>
    </table>

    <div class="card">
        <div class="card-body">
            <p class="card-text">
                * You can order and cancel your plan after login in the "Account" menu in the section "Your Plan".
                After ordering your paid plan you'll get a 14 days trial period. If you cancel within 14 days you'll not
                get charged. If you need more space than 20 GB, we'll be happy to provide a custom offer.
            </p>
        </div>
    </div>

</div>
