<ngb-alert *ngIf="(affiliate.promotion | async) && !closed" [type]="'info'" (close)="closed = true">

    <h4 class="alert-heading">Promotion!</h4>
    <p>If you register via this page, you get a special {{ (affiliate.promotion | async)?.label }} discount: on any
        paid plan you'll
        <strong>save {{ ((affiliate.promotion | async)?.customer_discount.percentage)*100 | number }}%
            for the first {{ (affiliate.promotion | async)?.customer_discount.duration }} months!</strong>
        Of course you still get the free plan with 100MB for free and you have a free 14 days trial period with the
        paid plans.
    </p>
    <p>Share this promotion link with your friends:
        <a [href]="'/promo/'+ (affiliate.promotion | async)?.name" class="alert-link" target="_blank">
            {{hostname}}/promo/{{(affiliate.promotion | async)?.name}}
        </a>
    </p>

</ngb-alert>
