import {Component} from '@angular/core';
import { NgbCarousel, NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { PromotionNotificationComponent } from '../promotion-notification/promotion-notification.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    standalone: true,
    imports: [RouterLink, PromotionNotificationComponent, NgbCarousel, NgbSlide]
})
export class AboutComponent {

}
