import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../notification.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {LoginService} from 'api-client';
import { EqualValidator } from '../cbfp-common/directives/equal-validator.directive';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'app-password-restore',
    templateUrl: './password-restore.component.html',
    styleUrls: ['./password-restore.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, FormsModule, NgClass, EqualValidator, DatePipe]
})
export class PasswordRestoreComponent implements OnInit {

    loading = true;
    loadingReset = false;

    resetIdentifier: string;
    password: string;
    passwordConfirm: string;

    login: string;
    valid = false;
    validUntil: number;

    now = Date.now();

    constructor(private loginService: LoginService,
                private activatedRoute: ActivatedRoute,
                private notify: NotificationService) {

    }

    ngOnInit() {
        const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
        this.resetIdentifier = queryParamMap.get('id');

        this.loginService.getPasswordReset(this.resetIdentifier)
            .subscribe(result => {
                this.login = result.login;
                this.valid = result.valid;
                this.validUntil = result.validUntil * 1000;
                this.loading = false;
            }, error => {
                this.loading = false;
                this.notify.error('Could not load reset code!', error.message);
            });
    }

    submit() {
        this.loadingReset = true;
        this.loginService.resetPassword(this.resetIdentifier, {password: this.password})
            .subscribe(() => {
                this.loadingReset = false;
                const title = 'Password Reset Successful';
                const msg = 'Your new password was set - you can log in now!<br/><br/>' +
                    'Please mind: The password is case sensitive.';
                this.notify.success(msg, title);
            }, error => {
                this.loadingReset = false;
                this.notify.error('Could not reset password!', error.message);
            });
    }

}
