<div class="jumbotron cbfp-bg-image">
    <div class="p-3 col-md-8 col-lg-6" style="background-color: white; max-width: 600px">
        <h1>Cloud Backup</h1>
        <div class="cbfp-subheader">for Podio</div>
        <!-- intentionally using larger image here for clear impression on 4K monitors: -->
        <img class="mx-auto img-fluid m-3" height="93" width="310"
             src="../../assets/podio-authorized-partner.jpg"
             alt="We are a authorized Podio partner"/>
        <p class="lead">
            Backup and restore your data stored at <a href="https://www.podio.com" target="_blank">Podio.com</a>.
            Your backup is stored independently from Podio® and hence can be accessed even if all Podio®
            infrastructure or service fails.
        </p>
        <div>
            <a routerLink="/view/register" class="btn btn-primary btn-lg" role="button">Register for free now!</a>
        </div>
    </div>
</div>

<div class="mx-md-5">
    <app-promotion-notification></app-promotion-notification>
</div>

<div class="jumbotron jumbo-even">
    <div class="row">
        <div class="col-md-5">
            <i class="fa fa-question icon-image" aria-hidden="true"></i>
        </div>
        <div class="col-md-7">
            <h3>Why bother?</h3>
            <p>
                We believe that Podio® is doing a very good job, keeping your data accessible. But we believe
                even more in a redundant, independent backup.
            </p>
            <p>
                If you have critical data stored at Podio® (e.g. billing or contact information) it may damage
                your business badly if Podio® is for some reason not accessible. For these cases you could access
                your data via Cloud Backup for Podio.
            </p>
        </div>
    </div>
</div>

<div class="jumbotron jumbo-odd">
    <div class="row">
        <div class="col-md-5 align-middle">
            <!--i class="fa fa-hdd-o icon-image" aria-hidden="true"></i-->
            <i class="fa fa-save icon-image" aria-hidden="true"></i>
        </div>
        <div class="col-md-7">
            <h3>Backup your Podio data</h3>
            <p>
                The backup functionality is - of course - the central feature.
                Items and other elements are stored in their <strong>original format</strong> - as retrieved from
                the Podio API.
            </p>
            <div>
                Backups include:
                <ul>
                    <li><strong>Item</strong> comments</li>
                    <li>Item tags</li>
                    <li>Item excel export</li>
                    <li>All <strong>files</strong> (attachments/images) uploaded to Podio®</li>
                    <li>Files stored at other providers (<a href="https://www.dropbox.com"
                                                            target="_blank">dropbox.com</a>,
                        ...) are linked for easy access
                    </li>
                    <li><strong>Tasks</strong> associated with selected workspace and global tasks
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron jumbo-even">
    <div class="row">
        <div class="col-md-5">
            <i class="fa fa-cloud-upload icon-image" aria-hidden="true"></i>
        </div>
        <div class="col-md-7">
            <h3>Restore to Podio</h3>
            <p>
                In case you (or some other mad man) <strong>changed or deleted</strong> your data, you can restore
                it from your backups.
            </p>
            <div>
                <ul>
                    <li><strong>Items</strong>, including their <strong>files</strong>, images and
                        <strong>comments</strong></li>
                    <li>Bulk restore multiple/all items of some app</li>
                    <li><strong>Tasks</strong>, including files and comments</li>
                    <li><strong>Apps</strong> including configuration and <strong>fields</strong></li>
                    <li>Restore automatically creates <strong>updated references</strong> (e.g. a restored task will
                        reference the before restored item)
                    </li>
                    <li>Coming soon: Restoring complete workspaces</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron jumbo-odd text-center" style="background-color: #009ee2">

    <h3 class="pb-3">What do our customers say?</h3>

    <ngb-carousel [interval]="false">
        <ng-template ngbSlide>
            <div class="row justify-content-center">
                <div class="col-12 col-md-9">
                    <div class="avatar pb-2">
                        <img src="../../assets/testimonial_yoram.jpg" class="rounded-circle img-fluid"
                             alt="Portrait Yoram Zara, Attorney Zara Law">
                    </div>
                    <p><i class="fa fa-3x fa-quote-left"></i>
                        I run my law practice on Podio. Without Podio, me and my employees cannot work. Monday morning,
                        while
                        trying to tidy up our central Podio app, I accidentally deleted it. I was in shock.
                        <strong>Seeing all the leads in the system disappear made me very worried.</strong>
                        I immediately contacted Daniel from Cloud Backup. He was very supportive and
                        <strong>we were able to restore the app</strong>, including all the associated data.
                        I could not thank them enough.
                        Me, my family, my customers and my team are grateful for their amazing job.</p>
                    <p class="mb-0">
                        <strong>I could not recommend this service more.
                            Using Cloud Backup for Podio was one of the wisest decisions I ever made.</strong>
                        <i class="fa fa-3x fa-quote-right"></i>
                    </p>
                    <h4>Yoram Zara</h4>
                    <h6>Attorney, Zara Law</h6>
                </div>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="row justify-content-center">
                <div class="col-12 col-md-9">
                    <div class="avatar pb-2">
                        <img src="../../assets/testimonial_dominik.jpg" class="rounded-circle img-fluid"
                             alt="Portrait Dominik Schreiber, Founder of wechselfuchs.de">
                    </div>
                    <p><i class="fa fa-3x fa-quote-left"></i>
                        <strong>Cloud Backup for Podio is the perfect service be on the safe side.</strong></p>
                    <p>
                        We as a startup, constantly try to
                        change things - and along the way there is of course always the risk of breaking things.
                        To know <strong>we can always access our data</strong>, lets us sleep so much more peacefully.
                    </p>
                    <p class="mb-0">
                        <strong>We don't worry too much any more about new staff, deleting data on Podio or even a Podio
                            outage.</strong>
                        <i class="fa fa-3x fa-quote-right"></i>
                    </p>
                    <h4>Dominik Schreiber</h4>
                    <h6>Founder at wechselfuchs.de</h6>
                </div>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="row justify-content-center">
                <div class="col-12 col-md-9">
                    <div class="avatar pb-2">
                        <img src="../../assets/testimonial_frank.jpg" class="rounded-circle img-fluid"
                             alt="Portrait Frank Dirks, Chief IT Advisor at MEDG">
                    </div>
                    <p><i class="fa fa-3x fa-quote-left"></i>
                        We are a Myanmar based NGO providing child education.
                        To archive efficient planning and operation, our 150 staff members heavily rely on Podio.
                        Basically, all our data is in Podio - so <strong>naturally we need an independent
                            backup</strong>.
                    </p>
                    <p>
                        Cloud Backup for Podio not only performs this backup in a reliable way, <strong>they also offer
                        great support</strong>.
                        They are very responsive, and when we had a feature request, they made sure to implement it
                        really fast.
                    </p>
                    <p class="mb-0">
                        <strong>
                            I totally recommend using Cloud Backup for Podio for anyone relying on Podio!
                        </strong>
                        <i class="fa fa-3x fa-quote-right"></i>
                    </p>
                    <h4>Frank Dirks</h4>
                    <h6>Chief IT Advisor, Monastic Education Development Group</h6>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>
    <a href="mailto:info@cloud-backup-for-podio.com" class="btn btn-lg btn-light mt-5">
        <i class="fa fa-envelope"></i>
        Tell us what you think!
    </a>
</div>

<div class="jumbotron jumbo-even">
    <div class="row">
        <div class="col-md-5">
            <i class="fa fa-shield icon-image" aria-hidden="true"></i>
        </div>
        <div class="col-md-7">
            <h3>Always secure</h3>
            <p>
                Cloud Backup for Podio was build with security and reliability as the main focus.
                Our system is completely independent from Podio to ensure a true redundancy.
            </p>
            <div>
                <ul>
                    <li>Independent from Podio® (Podio® is only contacted for fetching the backups).
                        <strong>Infrastructure completely separated</strong> from Podio® and hosted on
                        <a href="https://cloud.google.com/why-google/" target="_blank">Google Compute
                            Engine</a> (Region: europe-west1 in Belgium and europe-west3 in Germany).
                    </li>
                    <li>
                        All data is <strong>transferred securely</strong> via SSL/HTTPS (that is: from Podio® to
                        Cloud
                        Backup and from Cloud Backup to you). Your data will not be shared with any third party.
                    </li>
                    <li>
                        You can <strong>access your data through our website</strong> even if Podio is
                        completely/temporarily
                        out of service!
                    </li>
                    <li>Your data is <strong>stored redundantly</strong> in separate locations to ensure maximum
                        reliability
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron jumbo-odd">
    <div class="row">
        <div class="col-md-5">
            <i class="fa fa-lightbulb-o icon-image" aria-hidden="true"></i>
        </div>
        <div class="col-md-7">
            <h3>Further features</h3>
            <p>
                Cloud Backup for Podio includes many more features. Check it out by
                <a routerLink="/view/register">registering for free!</a>
            </p>
            <div>
                <ul>
                    <li><strong>Complete download</strong> of your Cloud Backup for Podio account to your computer.
                        This data can be restored to Cloud Backup (and from there to Podio).
                    </li>
                    <li><strong>CSV export</strong> of your items (Easy import in MS Excel or Open Office Calc)
                    </li>
                    <li>CSV export of comments</li>
                    <li>CSV export of tasks and their comments</li>
                    <li>
                        <strong>Automatic/Scheduled backups</strong> (Silver and Gold Plan only)
                    </li>
                    <li>Automatic removal of old backups (Silver and Gold Plan only)
                    </li>
                    <li>
                        <strong>Email notification</strong> (on success/failure) of backup.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <a routerLink="/view/register" class="btn btn-xl btn-primary w-100 mb-4">Register for free now!</a>
</div>
