import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../auth.service';
import { AsyncPipe } from '@angular/common';
import { LoginComponent } from '../login/login.component';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    standalone: true,
    imports: [LoginComponent, AsyncPipe]
})
export class LoginPageComponent implements OnInit {

    redirectUrl: Observable<string>;

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
        this.redirectUrl = this.authService.redirectUrl;
    }

}
