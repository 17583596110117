import {Routes} from '@angular/router';
import {AboutComponent} from '../about/about.component';
import {RegisterComponent} from '../register/register.component';
import {PriceComponent} from '../price/price.component';
import {FaqComponent} from '../faq/faq.component';
import {LoginGuardService} from '../login-guard.service';
import {LegalComponent} from '../legal/legal.component';
import {PasswordLostComponent} from '../password-lost/password-lost.component';
import {PasswordRestoreComponent} from '../password-restore/password-restore.component';
import {LoginPageComponent} from '../login-page/login-page.component';

export const appRoutes: Routes = [
    {path: 'view/about', component: AboutComponent},
    {path: 'view/register', component: RegisterComponent},
    {path: 'register/promo/:promoId', component: RegisterComponent},
    {path: 'view/price', component: PriceComponent},
    {path: 'view/faq', component: FaqComponent},
    {path: 'view/legal', component: LegalComponent},
    {path: 'view/password-lost', component: PasswordLostComponent},
    {path: 'view/password-restore', component: PasswordRestoreComponent},
    {path: 'view/login', component: LoginPageComponent},
    {
        path: 'view/backupcollection',
        canActivate: [LoginGuardService],
        loadChildren: () => import('app/backup/backup.module').then(m => m.BackupModule)
    },
    {
        path: 'view/account',
        canActivate: [LoginGuardService],
        loadChildren: () => import('app/account/account.module').then(m => m.AccountModule)
    },
    {
        path: 'view/export-import',
        canActivate: [LoginGuardService],
        loadChildren: () => import('app/export/export.module').then(m => m.ExportModule)
    },
    {
        path: 'view/batch',
        canActivate: [LoginGuardService],
        loadChildren: () => import('app/batch/batch.module').then(m => m.BatchModule)
    },
    {
        path: 'view/admin',
        canActivate: [LoginGuardService],
        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: '',
        redirectTo: '/view/about',
        pathMatch: 'full'
    },
    {path: '**', component: AboutComponent}
];


