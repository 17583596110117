<div class="container">
    <h1 class="py-4">Please log in!</h1>

    <app-login></app-login>

    <small class="text-muted mt-3 d-block">
        Will redirect to: <a [href]="redirectUrl | async">{{redirectUrl | async}}</a>
    </small>

</div>
