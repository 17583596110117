import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NotificationService} from '../notification.service';
import {DomSanitizer} from '@angular/platform-browser';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-notification-toast-container',
    templateUrl: './notification-toast-container.component.html',
    styleUrls: ['./notification-toast-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgbToast, AsyncPipe]
})
export class NotificationToastContainerComponent {
  autoHide = true;

  constructor(public notificationService: NotificationService,
              private sanitizer: DomSanitizer) {
    notificationService.toastNotifications$.subscribe((notification) => {
      console.log('notification', notification);
    });
  }

  safe(message: string) {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }
}
