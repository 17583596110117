import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AuthService} from '../auth.service';
import {NotificationService} from '../notification.service';
import {HttpErrorResponse} from '@angular/common/http';
import { StripHtmlPipe } from '../strip-html.pipe';
import { RouterLink } from '@angular/router';
import { OauthLinkComponent } from '../oauth-link/oauth-link.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';



@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, NgbPopover, OauthLinkComponent, RouterLink, StripHtmlPipe]
})
export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    loggedIn: boolean;

    @ViewChild('loginError')
    loginErrorTemplate: TemplateRef<any>;

    constructor(private notificationService: NotificationService,
                private authenticationService: AuthService) {
    }

    ngOnInit() {
        this.loggedIn = this.authenticationService.isLoggedIn();
        this.authenticationService.loginUpdated.subscribe(
            (loggedIn) => {
                console.log('received log in event: ' + loggedIn);
                this.loggedIn = loggedIn;
            }
        );
    }

    logout() {
        this.authenticationService.logout();
    }

    login() {
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password)
            .then(data => {
                this.model.username = '';
                this.model.password = '';
                this.loading = false;
            }).catch(error => {
            this.loading = false;
            this.notificationService.forTemplate('Login failed!', this.loginErrorTemplate, {
              error: error instanceof HttpErrorResponse ? error.statusText : error.message
            });
        });
    }
}
