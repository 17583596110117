import {Injectable} from '@angular/core';
import {ExtraOptions} from '@angular/router';
import {NotificationService} from './notification.service';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class CbfpRoutingExtraOptions implements ExtraOptions {

  errorHandler = error => {
    const errorMsg = this.sanitizer.bypassSecurityTrustHtml('The loaded UI is outdated, please <a href="javascript:window.location.reload();">reload</a> the website. ' +
      'Most likely, we just deployed a new version :-)<br><br>' +
      'If this problem persists, please contact <a href="mailto:info@cloud-backup-for-podio.com">support</a>!');

    console.error('routing error: ', error);
    this.notify.error(errorMsg, error ? error.message : '')
  };

  constructor(private notify: NotificationService, private sanitizer: DomSanitizer) {

  }

}
