<div class="container mb-4">

  <h1 class="py-4">Legal Disclosure</h1>

  <div class="row g-4">
    <div class="col-12 col-lg-6">
      <div class="card h-100">
        <div class="card-body">
          <h4 class="card-title">Contact</h4>
          <p class="card-text">
            Telephone: +49-178-1972175<br/>
            E-Mail: <a href="mailto:info@cloud-backup-for-podio.com">info&#64;cloud-backup-for-podio.com</a><br/>
            Internet: <a href="https://www.cloud-backup-for-podio.com"
                         target="_blank">www.cloud-backup-for-podio.com</a>
          </p>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <div class="card h-100">
        <div class="card-body">
          <h4 class="card-title">Information in accordance with section 5 TMG</h4>
          <p class="card-text">
            Daniel Schreiber<br/>Dietrich-Bonhoeffer-Str. 29<br/>63075 Offenbach am Main, Germany<br/>
            Tax No: DE298228223
          </p>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Privacy Notice</h4>
          <p class="card-text">
            Data protection is of a particularly high priority for our service. The use of our website is possible
            without any indication of personal data; however, if a user registers an account, processing of
            personal data becomes necessary.
          </p>
          <p class="card-text">
            The processing of personal data, such as your e-mail address or your backup data shall always be in
            line with the General Data Protection Regulation (GDPR). By means of this privacy notice, we would
            like to inform you of the nature, scope, and purpose of the personal data we collect, use and process.
          </p>
          <h5>Security Measures</h5>
          <p class="card-text">
            Your personal information is contained behind secured networks and is only accessible by a limited
            number of persons who have special access rights to such systems, and are required to keep the
            information confidential. In addition, all information you supply is encrypted via Secure Socket Layer
            (SSL) technology.
          </p>
          <h5>Data</h5>
          <p class="card-text">
            We only collect data that is necessary for the provision of our service, such as your e-mail and parts
            of your connected Podio profile (e-mail, credentials). For paid subscriptions we collect payment data
            through Stripe (see “Third Parties”). Furthermore we collect data from Podio as requested or
            configured by the user to perform the backups.
          </p>
          <p class="card-text">
            We do not use automatic decision-making or profiling.
          </p>
          <h5>Third Parties</h5>
          <p class="card-text">
            To provide our service in a secure and reliable way, the following services are used:
          </p>
          <table class="table">
            <thead>
            <tr>
              <th>Company</th>
              <th>Address/country</th>
              <th>Service</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row">Google Ireland Limited</th>
              <td>Gordon House<br>
                Barrow Street<br>
                Dublin 4<br>
                Ireland
              </td>
              <td>Provisioning of infrastructure (virtual machines
                incl. data storage) by the Google Compute Engine.
              </td>
            </tr>
            <tr>
              <th scope="row">Stripe Payments Europe Ltd.</th>
              <td>Block 4, Harcourt Centre<br>
                Harcourt Road<br>
                Dublin 2<br>
                Ireland
              </td>
              <td>Payment services.</td>
            </tr>
            <tr>
              <th scope="row">Plausible Insights OÜ</th>
              <td>Västriku tn 2<br>
                50403 Tartu<br>
                Estonia
              </td>
              <td>
                Privacy first analytics services.<br>
                No personally identifiable data (PII) is shared with this service.
              </td>
            </tr>
            </tbody>
          </table>

          <h5>Your Rights</h5>
          <p class="card-text">
            You have the right to access your data, request data correction and erasure. Furthermore you can
            request a portable copy of the information you provided to us and you can withdraw any consent
            you gave us at any time. You can file a complaint with us or your local data protection authority at
            any time.
          </p>
          <p class="card-text">
            Most of these actions you can perform through our website, for all other requests, please contact
            <a href="mailto:privacy@cloud-backup-for-podio.com">privacy&#64;cloud-backup-for-podio.com</a>
          </p>
          <h5>Data Protection Officer</h5>
          <p class="card-text">
            For any concerns regarding this privacy policy, you can contact our Data Protection Officer:
          </p>
          <p class="card-text">
            Daniel Schreiber<br/>
            Dietrich-Bonhoeffer-Str. 29<br/>
            63075 Offenbach am Main<br/>
            Germany<br/>
            <a href="mailto:privacy@cloud-backup-for-podio.com">privacy&#64;cloud-backup-for-podio.com</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col-12">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Disclaimer</h4>
          <h5>Accountability for content</h5>
          <p class="card-text">
            The contents of our pages have been created with the utmost care.
            However, we cannot guarantee the contents' accuracy, completeness or topicality. According to statutory
            provisions,
            we are furthermore responsible for our own content on these web pages. In this context, please note that
            we are
            accordingly not obliged to monitor merely the transmitted or saved information of third parties, or
            investigate
            circumstances pointing to illegal activity. Our obligations to remove or block the use of information
            under
            generally applicable laws remain unaffected by this as per &sect;&sect; 8 to 10 of the Telemedia Act
            (TMG).
          </p>
          <h5>Accountability for links</h5>
          <p class="card-text">
            Responsibility for the content of external links (to web
            pages of third
            parties) lies solely with the operators of the linked pages. No violations were evident to us at the
            time of
            linking. Should any legal infringement become known to us, we will remove the respective link
            immediately.
          </p>
          <h5>Copyright</h5>
          <p class="card-text">
            Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law
            (&sect; 44a
            et seq. of the copyright law), every form of utilizing, reproducing or processing works subject to
            copyright
            protection on our web pages requires the prior consent of the respective owner of the rights. Individual
            reproductions of a work are allowed only for private use, so must not serve either directly or
            indirectly for
            earnings. Unauthorized utilization of copyrighted works is punishable (&sect; 106 of the copyright
            law).
          </p>
          <p class="card-text">
            <i>Source: </i>
            <a href="http://www.muster-vorlagen.net" target="_blank">
              www.Muster-Vorlagen.net - Impressum Generator
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
