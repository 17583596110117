import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class FaqComponent implements OnInit, OnDestroy {

  isCollapsed1 = true;
  isCollapsed2 = true;
  isCollapsed3 = true;
  isCollapsed4 = true;
  isCollapsed5 = true;
  isCollapsed6 = true;
  isCollapsed7 = true;
  isCollapsed8 = true;
  isCollapsed9 = true;
  isCollapsed10 = true;
  isCollapsed11 = true;
  isCollapsed12 = true;
  isCollapsed13 = true;

  private sub: Subscription;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    // workaround for non-working anchorScrolling of angular router:
    this.sub = this.route.fragment.pipe(
      filter(f => !!f)
    ).subscribe(f => {
      const element = document.querySelector('#' + f)
      if (element) {
        element.scrollIntoView()
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
