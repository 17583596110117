import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {NotificationService} from './notification.service';

@Injectable()
export class CbfpErrorHandler extends ErrorHandler {

    constructor(private injector: Injector) {
        super();
    }

    /**
     * This could be optimized by sending errors to server/monitoring..
     */
    handleError(error) {
        const notify = this.injector.get(NotificationService);
        const message = error.message ? error.message : error.toString();
        notify.error('Some unexpected error occurred. ' +
            'Please reload page and try again. ' +
            'If the error persists, please contact <a href="mailto:info@cloud-backup-for-podio.com">support</a>', message);

        super.handleError(error);
    }
}
