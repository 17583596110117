<div class="container">

    <h1 class="py-4">Frequently Asked Questions</h1>

    <p class="card-text">If your question is not answered below, please write an email to
        <a href="mailto:info@cloud-backup-for-podio.com">info&#64;cloud-backup-for-podio.com</a>! </p>


    <div class="card hand mb-4" id="create-first" (click)="isCollapsed1 = !isCollapsed1">
        <div class="card-body">
            <h4 class="card-title">I created a backup, but nothing happens - what
                went wrong?</h4>
            <p class="card-text">Nothing. Just select the created backup
                <span *ngIf="isCollapsed1">...</span>
                <span *ngIf="!isCollapsed1">in the navigation on the left. In the following
            page click the button "Run Backup Iteration".
                <img src="../../assets/faq/create-first-iteration.png" class="mx-auto img-fluid"/></span>
            </p>
        </div>
    </div>

    <div class="card hand mb-4" id="estimate-quota" (click)="isCollapsed2 = !isCollapsed2">
        <div class="card-body">
            <h4 class="card-title">How can I estimate how much quota I need for the backup of my
                workspaces?</h4>

            <p class="card-text">The quota - a backup occupies - is difficult to estimate. It is
                <span *ngIf="isCollapsed2">...</span>
                <span *ngIf="!isCollapsed2">
            mainly determined by the amount and size
            of files included in your workspace. The easiest way to find out, is to simply create a backup! For
                that, the 14 days trial period in the silver/gold plan is excellent.</span></p>

            <p class="card-text" *ngIf="!isCollapsed2">Please mind, that
                <a routerLink="/view/faq" fragment="first-storage">
                    subsequent backups will most likely need much less quota</a>
                than the first one!</p>
        </div>
    </div>

    <div class="card hand mb-4" id="first-storage" (click)="isCollapsed3 = !isCollapsed3">
        <div class="card-body">
            <h4 class="card-title">My first backup used X of my quota/storage - will any subsequent
                backups add that
                much?</h4>

            <p class="card-text">No. Most of the storage is occupied by
                <span *ngIf="isCollapsed3">...</span>
                <span *ngIf="!isCollapsed3">
            backed up files (e.g. images). Identical files
            are stored only once for all backups - hence subsequent backups should be much smaller than the first
                one. (Of course the backed up files/images remain if you only delete your first backup!)</span></p>
        </div>
    </div>

    <div class="card hand mb-4" id="more-storage" (click)="isCollapsed4 = !isCollapsed4">
        <div class="card-body">
            <h4 class="card-title">20 GB might be not sufficient for my needs - can I get more?</h4>

            <p class="card-text">Yes. If you come btn-close to the limit
                <span *ngIf="isCollapsed4">...</span>
                <span *ngIf="!isCollapsed4">
            (check in on the
            <a routerLink="/view/account">account page</a>), give
                <a href="mailto:info@cloud-backup-for-podio.com">customer support</a> a notice and we'll
                provide a custom offer.</span>
            </p>
        </div>
    </div>

    <div class="card hand mb-4" id="slow" (click)="isCollapsed5 = !isCollapsed5">
        <div class="card-body">
            <h4 class="card-title">My first backup took a very long time - is that normal?</h4>

            <p class="card-text">Yes. Subsequent backups might be faster, as identical files
                <span *ngIf="isCollapsed5">...</span>
                <span *ngIf="!isCollapsed5">
            might not be downloaded
            again. Note: The duration is mainly limited by rate limits imposed by Podio&reg; - please don't blame
                Cloud Backup for Podio.</span>
            </p>
        </div>
    </div>

    <div class="card hand mb-4" id="restore" (click)="isCollapsed6 = !isCollapsed6">
        <div class="card-body">
            <h4 class="card-title">Is it possible to restore a backup to Podio somehow?</h4>

            <p class="card-text"> Yes! Right now you can restore individual items, tasks and apps
                <span *ngIf="isCollapsed6">...</span>
                <span *ngIf="!isCollapsed6">
            - including all their field values, files,
                images, references and comments. This works for changed and deleted items/tasks/apps. </span>
            </p>

            <p class="card-text" *ngIf="!isCollapsed6">Below the item restore functionality is shown, the same for tasks
                is found on the iteration level (select
                the date in the menu).</p>

            <p class="card-text" *ngIf="!isCollapsed6"><img src="../../assets/faq/restore-item.png" class="mx-auto img-fluid"></p>

            <p class="card-text" *ngIf="!isCollapsed6">We are working hard on restoring complete workspaces. Follow us
                on Twitter to keep up with new features:
                <a href="https://twitter.com/BackupForPodio">&#64;BackupForPodio</a>.
            </p>
        </div>
    </div>

    <div class="card hand mb-4" id="restore_app" (click)="isCollapsed7 = !isCollapsed7">
        <div class="card-body">
            <h4 class="card-title">What happens during app restore and what happens to items in the
                app when I restore
                app?</h4>
            <p class="card-text"> Currently the app restore is independent from the item restore, thus
                <span *ngIf="isCollapsed7">...</span>
                <span *ngIf="!isCollapsed7">
            your items will not change when you
            restore an app. With app restore configuration and fields are restored, thereby the external
            field ids might change, but this has no effect on Podio. Only other external apps might be
                affected. </span>
            </p>
        </div>
    </div>

    <div class="card hand mb-4" id="complete_restore" (click)="isCollapsed8 = !isCollapsed8">
        <div class="card-body">
            <h4 class="card-title">Can I restore an app with all its items?</h4>
            <p class="card-text">Yes! At the moment you need to do two steps: First restore the app and
                <span *ngIf="isCollapsed8">...</span>
                <span *ngIf="!isCollapsed8">
            second restore all items (using
                the batch item restore).</span>
            </p>
            <p class="card-text" *ngIf="!isCollapsed8">We are working hard on restoring complete workspaces. Follow us
                on Twitter to keep up with new features:
                <a href="https://twitter.com/BackupForPodio">&#64;BackupForPodio</a>.
            </p>
        </div>
    </div>

    <div class="card hand mb-4" id="export" (click)="isCollapsed9 = !isCollapsed9">
        <div class="card-body">
            <h4 class="card-title">Where do I find the CSV export for items/comments?</h4>

            <p class="card-text">The export is found on the app level. Drill down
                <span *ngIf="isCollapsed9">...</span>
                <span *ngIf="!isCollapsed9">
            in your backup (perform one first!) to the
                lowest level - there you'll find an 'Export' tab next to the files tabs. See screenshot:</span>
            </p>

            <p class="card-text" *ngIf="!isCollapsed9"><img src="../../assets/faq/export-csv.png" class="mx-auto img-fluid"></p>

            <p class="card-text" *ngIf="!isCollapsed9">The export functionality found on the 'Export' page is separate
                and lets you download all your data for
                importing it later on back to Cloud Backup for Podio</p>
        </div>
    </div>

    <div class="card hand mb-4" id="podio-password" (click)="isCollapsed10 = !isCollapsed10">
        <div class="card-body">
            <h4 class="card-title">Can I use this app without providing my Podio
                credentials/password?</h4>

            <p class="card-text">Yes! You can authorize this app via Podio
                <span *ngIf="isCollapsed10">...</span>
                <span *ngIf="!isCollapsed10">
            - this does not let Cloud Backup for Podio access your Podio
            credentials. Nonetheless, you can provide your Podio credentials on the
            <a routerLink="view/account">account page</a>
                to ensure reliable backups!</span>
            </p>

        </div>
    </div>

    <div class="card hand mb-4" id="multiple-oauth" (click)="isCollapsed11 = !isCollapsed11">
        <div class="card-body">
            <h4 class="card-title">Why are there multiple Podio extensions listed on the account
                page?</h4>

            <p class="card-text">To ensure reliable and performant backups and
                <span *ngIf="isCollapsed11">...</span>
                <span *ngIf="!isCollapsed11">
            user experience, we use multiple (technical) Podio
            extensions. You need to enable each of them separately on the
            <a routerLink="/view/account">account page</a>.</span>
            </p>
            <p class="card-text" *ngIf="!isCollapsed11">If you provided your Podio credentials/password, you don't need
                to worry about this, as we can
                automatically use multiple extensions.
            </p>

        </div>
    </div>

    <div class="card hand mb-4" id="forgot-password" (click)="isCollapsed12 = !isCollapsed12">
        <div class="card-body">
            <h4 class="card-title">I forgot my password - how can I reset it?</h4>

            <p class="card-text">You can request a password reset link on

                <span *ngIf="isCollapsed12">...</span>
                <span *ngIf="!isCollapsed12">
            <a routerLink="/view/password-lost">this page</a>. This link lets you
                set a new password. It will be send to the email address you registered with.</span>
            </p>

            <p class="card-text" *ngIf="!isCollapsed12">When logged in, you can set a new password on the
                <a routerLink="view/account">account page</a>.
            </p>
            <p class="card-text" *ngIf="!isCollapsed12">Please contact customer support
                <a href="mailto:info@cloud-backup-for-podio.com">info&#64;cloud-backup-for-podio.com</a>
                if you encounter any problems with the password reset.</p>
        </div>
    </div>

    <div class="card hand mb-4" id="eol" (click)="isCollapsed13 = !isCollapsed13">
        <div class="card-body">
            <h4 class="card-title">What happens if the service at Cloud Backup for Podio is
                discontinued?</h4>

            <p class="card-text">In the unlikely case, the service is discontinued, we will
                <span *ngIf="isCollapsed13">...</span>
                <span *ngIf="!isCollapsed13">
            inform customers on long notice. Furthermore
            the software running this website will be made available as open source, so that anyone can run it on
                its own servers and as well import the data from this website. So in any case you won't be lost!</span>
            </p>

            <p class="card-text" *ngIf="!isCollapsed13">If that does not ease your concerns, please inquire
                <a href="mailto:info@cloud-backup-for-podio.com">customer support</a>
                about an offer for a on-premise solution.</p>
        </div>
    </div>

</div>
