<nav class="navbar navbar-expand-lg bg-light" id="nav">
    <span class="w-100 d-lg-none">
        <button (click)="isCollapsed = !isCollapsed"
                class="navbar-toggler" type="button"
                aria-controls="bd-main-nav"
                aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <a class="navbar-brand pull-right" routerLink="/" (click)="isCollapsed = true">
            <img src="../assets/logo.svg" height="35" width="73" style="max-height: 2rem">
        </a>
    </span>

    <div class="navbar-collapse" id="bd-main-nav"
         [attr.aria-expanded]="!isCollapsed" [ngClass]="{collapse: isCollapsed}">
        <a class="navbar-brand float-end d-none d-lg-block" routerLink="/">
            <img src="../assets/logo.svg" height="70" width="146" style="max-height: 3rem">
        </a>
        <ul class="navbar-nav me-auto">
            <li class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/about" class="nav-link" routerLinkActive="active">About</a>
            </li>
            <li *ngIf="user" class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/account" class="nav-link" routerLinkActive="active">Account</a>
            </li>
            <li *ngIf="user" class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/backupcollection" class="nav-link" routerLinkActive="active">Backups</a>
            </li>
            <li *ngIf="user" class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/export-import" class="nav-link" routerLinkActive="active">Export</a>
            </li>
            <li *ngIf="user" class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/batch" class="nav-link" routerLinkActive="active">Batch</a>
            </li>
            <li *ngIf="authService.hasRight('viewAdminPage')" class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/admin" class="nav-link" routerLinkActive="active">Admin</a>
            </li>
            <li *ngIf="!user" class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/register" class="nav-link" routerLinkActive="active">Register</a>
            </li>
            <li class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/price" class="nav-link" routerLinkActive="active">Pricing</a>
            </li>
            <li class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/faq" class="nav-link" routerLinkActive="active">FAQ</a>
            </li>
            <li class="nav-item" (click)="isCollapsed = true">
                <a routerLink="/view/legal" class="nav-link" routerLinkActive="active">Legal</a>
            </li>

        </ul>
        <div *ngIf="user" class="text-muted">
            <span class="fa fa-user"></span>
            {{user}}
        </div>
        <app-login class="float-end ps-2"></app-login>
    </div>
</nav>

<router-outlet></router-outlet>

<app-notification-toast-container></app-notification-toast-container>

<div *ngIf="loading" class="global-loader">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
</div>
