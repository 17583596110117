import {Component, OnInit} from '@angular/core';
import {AuthService} from './auth.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { NotificationToastContainerComponent } from './notification-toast-container/notification-toast-container.component';
import { LoginComponent } from './login/login.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterLink, NgClass, RouterLinkActive, NgIf, LoginComponent, RouterOutlet, NotificationToastContainerComponent]
})
export class AppComponent implements OnInit {
    isCollapsed = true;
    user = null;

    loading = true;


    constructor(public authService: AuthService, private router: Router) {
        router.events.subscribe((event: Event) => {
            this.navigationInterceptor(event);
        });
    }

    ngOnInit() {
        this.user = this.authService.user;
        this.authService.loginUpdated.subscribe(loginChanged => this.user = this.authService.user);
    }

    private navigationInterceptor(event: Event) {
        if (event instanceof NavigationStart) {
            this.loading = true;
        }
        if (event instanceof NavigationEnd) {
            this.loading = false;
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false;
        }
        if (event instanceof NavigationError) {
            this.loading = false;
        }
    }
}
