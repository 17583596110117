import { Component } from '@angular/core';

@Component({
    selector: 'app-legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.css'],
    standalone: true
})
export class LegalComponent {

}
