<div class="container">

    <h1 class="py-4">Password Reset</h1>

    <p *ngIf="loading" class="text-center pt-4">
        <i class="fa fa-spinner fa-pulse fa-fw fa-3x"></i>
        <br><br>
        Loading reset code..
    </p>

    <ng-container *ngIf="!loading">
        <p *ngIf="!valid" class="alert alert-danger">
            This personal password reset page is expired:
            <strong>It was used already</strong>.</p>

        <p *ngIf="valid && validUntil < now" class="alert alert-danger">
            This personal password reset page is expired:
            <strong>It expired on {{validUntil | date:'short'}}</strong>.</p>

        <p *ngIf="!valid || validUntil < now">You can request a new password reset
            <a routerLink="/view/password-lost">here</a>.</p>

        <div class="card-deck" *ngIf="valid && validUntil > now">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Reset for {{login}}</h4>

                    <p class="card-text">This is a personal password reset page for: <strong>{{login}}</strong>.
                        It is valid until {{validUntil | date:'short'}}.</p>

                    <p class="card-text">Here only your login for <a href="https://www.cloud-backup-for-podio.com">www.cloud-backup-for-podio.com</a>
                        is reset - this password is managed independent from the password of your Podio&reg; account.
                    </p>

                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Set new password</h4>

                    <div class="card-text">
                        <form #resetPasswordForm="ngForm" (ngSubmit)="submit()">
                            <div class="mb-3 required"
                                 [ngClass]="{'has-danger': new_password_field.errors && (new_password_field.dirty || new_password_field.touched)}">
                                <label for="new_password_field">New password</label>
                                <input type="password" #new_password_field="ngModel" id="new_password_field"
                                       [(ngModel)]="password" required
                                       class="form-control" name="new_password_field">
                            </div>

                            <div class="mb-3 required"
                                 [ngClass]="{'has-danger': password_confirm.errors && (password_confirm.dirty || password_confirm.touched)}">
                                <label for="new-password-confirmation">Password confirm</label>
                                <input type="password" id="new-password-confirmation" #password_confirm="ngModel"
                                       [(ngModel)]="passwordConfirm" name="new-password-confirm"
                                       validateEqual="new_password_field" class="form-control">
                                <div *ngIf="password_confirm.errors && (password_confirm.dirty || password_confirm.touched)">
                                    <div class="form-control-feedback">Password inputs must coincide!</div>
                                </div>
                            </div>

                            <button type="submit" [disabled]="!resetPasswordForm.form.valid || loadingReset"
                                    class="btn btn-primary btn-block">
                                <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw"></i>
                                Reset password
                            </button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
