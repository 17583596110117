<form *ngIf="!loggedIn" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate class="form-inline">
    <input type="text" class="form-control mb-2 me-sm-2 mb-sm-0" name="username" [(ngModel)]="model.username"
           #username="ngModel"
           required placeholder="Login"/>
    <input type="password" class="form-control mb-2 me-sm-2 mb-sm-0" name="password" [(ngModel)]="model.password"
           #password="ngModel" maxlength="255"
           required placeholder="Password"/>
    <span ngbPopover="Enter login and password!" triggers="mouseenter:mouseleave" placement="bottom" [disablePopover]="!loading && f.form.valid">
        <button [disabled]="loading || !f.form.valid" class="btn btn-primary mb-2 me-sm-2 mb-sm-0" #loginButton>
            <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw"></i>
            Login
        </button>
    </span>
    <app-oauth-link class="mb-2 me-sm-2 mb-sm-0">Login via Podio</app-oauth-link>
</form>
<button *ngIf="loggedIn" class="btn btn-outline-secondary" (click)="logout()">Logout</button>

<ng-template let-error="error" let-close="close" #loginError>
    <pre><code [innerHtml]="error | stripHtml"></code></pre>
    <small>Password lost? You can reset your password <a (click)="close()" routerLink="/view/password-lost">here</a>.</small>
</ng-template>
