<ngb-toast
  *ngFor="let toast of notificationService.toastNotifications$ | async"
  [class]="{
    'bg-success': toast.type === 'success',
    'bg-danger': toast.type === 'error',
    'bg-warning': toast.type === 'warning',
    'bg-info': toast.type === 'info',
    'text-light': toast.type !== 'warning'
  }"
  class="mt-2 me-2"
  [autohide]="!!toast.autoHideMs && autoHide"
  [delay]="toast.autoHideMs"
  [header]="toast.title"
  (hidden)="notificationService.removeToastNotification(toast)"
  (mouseenter)="autoHide = false"
  (mouseleave)="autoHide = true"
>
  <div [innerHTML]="safe(toast.message)"></div>
</ngb-toast>
